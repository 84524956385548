img.MuiBox-root.css-6jrdpz{
    width: 100% !important;
    object-fit: scale-down !important;
    /* height: 300px !important; */
}
.returnConfirmtionImageContainer .css-uqxamn-MuiPaper-root-MuiCard-root{
    box-shadow: none !important;
}
.returnConfirmtionImageContainer .MuiBox-root.css-y1gt6f{
    margin-top: 0 !important;
}
.returnConfirmtionImageContainer .css-uqxamn-MuiPaper-root-MuiCard-root{
    padding: 0 !important;
    /* height: auto !important; */
    height: 360px !important;
}
.returnConfirmtionImageContainer .MuiGrid-root MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.css-1idn90j-MuiGrid-root{
    margin-bottom: 20px !important;
}