.pdf-container {
    width: 100%;
}
.pdf-page {
    object-fit: scale-down !important;
    width: 100% !important;
}
.react-pdf__Document{
    --scale-factor: 0 !important;
}
.react-pdf__Page.pdf-page{
    width: 100% !important;
}
.react-pdf__Page__canvas{
    width: 100% !important;
    /* height: auto !important; */
    object-fit: scale-down !important;
}
.react-pdf__Page__textContent.textLayer{
    display: none !important;
}

.react-pdf__Page.preview-pdf-file canvas{
    width: 100% !important;
    height: auto !important;
    object-fit: scale-down !important;
    --scale-factor: unset !important;
}
*{
    --scale-factor: unset !important;
}